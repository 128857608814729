import React, { useState, useEffect } from "react";
import "./OrderModal.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

function OrderModal({ setModal, data }) {
  const [category, setCategory] = useState("");
  const [loading, setloading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const uid = localStorage.getItem("id");

  const [cName, setName] = useState("");
  const [cMobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [DeliveryDhaka, setDeliveryDhaka] = useState(
    data.DeliveryCharge.insideDhaka
  );
  const [DeliveryOut, setDeliveryOut] = useState(
    data.DeliveryCharge.outsideDhaka
  );
  const [quantities, setQuantities] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductPrices, setSelectedProductPrices] = useState({});

  const handleIncrement = (productId) => {
    if (selectedProducts.includes(productId)) {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [productId]: (prevQuantities[productId] || 0) + 1,
      }));

      setSelectedProductPrices((prevPrices) => ({
        ...prevPrices,
        [productId]:
          data.Variations.find((v) => v.id === productId)?.price || 0,
      }));
    }
  };

  const handleDecrement = (productId) => {
    if (selectedProducts.includes(productId)) {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [productId]: Math.max((prevQuantities[productId] || 0) - 1, 0),
      }));

      setSelectedProductPrices((prevPrices) => ({
        ...prevPrices,
        [productId]:
          data.Variations.find((v) => v.id === productId)?.price || 0,
      }));
    }
  };

  const handleRadioChange = (productId) => {
    setSelectedProducts([productId]);

    setSelectedProductPrices({
      [productId]: data.Variations.find((v) => v.id === productId)?.price || 0,
    });

    setQuantities({
      [productId]: quantities[productId] || 1,
    });
  };

  const calculateTotalPrice = (productId) => {
    const price = selectedProductPrices[productId] || 0;
    const quantity = quantities[productId] || 1;
    return quantity * price;
  };

  const getDeliveryCharge = () => {
    return location === "dhaka"
      ? DeliveryDhaka
      : location === "outside"
      ? DeliveryOut
      : 0;
  };

  const calculateTotalSum = () => {
    const selectedProductId = Object.keys(selectedProductPrices)[0];

    if (!selectedProductId) {
      return 0;
    }

    const productTotal = calculateTotalPrice(selectedProductId);

    return productTotal;
  };

  const submitOrder = () => {
    setloading(true);
    // Uncomment and use your axios code here to submit the order
  };

  return (
    <div className="OrderModalBackground">
      <ToastContainer />
      <div className="OrderModalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => setModal(false)}>X</button>
        </div>
        <p
          style={{
            color: "black",
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          অর্ডার টি সম্পন্ন করতে আপনার নাম, মোবাইল নাম্বার ও ঠিকানা নিচে লিখুন
        </p>
        <div className="title">
          <Box sx={{ width: 500, maxWidth: "100%" }}>
            <TextField
              placeholder="আপনার নাম লিখুন "
              fullWidth
              label="Name"
              id="fullWidth"
              onChange={(e) => setName(e.target.value)}
              value={cName}
            />
          </Box>
        </div>
        <div className="title">
          <Box sx={{ width: 500, maxWidth: "100%" }}>
            <TextField
              placeholder="আপনার মোবাইল নম্বর লিখুন "
              fullWidth
              label="Mobile"
              id="fullWidth"
              onChange={(e) => setMobile(e.target.value)}
              value={cMobile}
            />
          </Box>
        </div>
        <div className="title">
          <Box sx={{ width: 500, maxWidth: "100%" }}>
            <TextField
              placeholder="আপনার ঠিকানা লিখুন "
              fullWidth
              label="Address"
              id="fullWidth"
              onChange={(e) => setAddress(e.target.value)}
              value={address}
            />
          </Box>
        </div>
        <div className="OrderMoDeliveryLocation">
          <FormControl onChange={(e) => setLocation(e.target.value)}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="dhaka"
                control={<Radio />}
                label={`ঢাকা সিটিতে ৳ ${data.DeliveryCharge.insideDhaka}`}
                style={{ margin: "0px" }}
              />
              <FormControlLabel
                value="outside"
                control={<Radio />}
                label={`সিটির বাহিরে ৳ ${data.DeliveryCharge.outsideDhaka}`}
                style={{ margin: "0px" }}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <p
          style={{
            color: "black",
            border: "1px solid #ccc",
            padding: "10px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          নিচে যে কোনো একটি সিলেক্ট করুন
        </p>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {data.Variations.length !== 0 &&
              data.Variations.map((data) => {
                return (
                  <div className="landing-select">
                    <img
                      src="https://cdn.ecomtechbd.com/file/name/image-1704218534054.r1.jpeg"
                      height={50}
                      width={50}
                    />
                    <p>{data.color}</p>
                    <p>{data.price}</p>
                    <div>
                      <button
                        className="dicButtonL"
                        onClick={() => handleDecrement(data.id)}
                      >
                        -
                      </button>

                      {/* <span>{quantities[data.id] || 1}</span> */}
                      <input
                        type="number"
                        class="Lan-Quantity-Input"
                        name="n-Quantity"
                        value={quantities[data.id] || 1}
                      ></input>

                      <button
                        className="increaseButtonL"
                        onClick={() => handleIncrement(data.id)}
                      >
                        +
                      </button>
                    </div>
                    <FormControlLabel
                      value={data.color}
                      control={<Radio />}
                      style={{ margin: "0px" }}
                      onChange={() => handleRadioChange(data.id)}
                    />
                  </div>
                );
              })}
          </RadioGroup>
        </FormControl>
        <p>Total Charge : ${calculateTotalSum() + getDeliveryCharge()}</p>
        <div className="OrderFooter">
          <button
            onClick={() => {
              setModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              submitOrder();
            }}
          >
            {loading ? "Loading.." : "Order Now!"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrderModal;
