import React from 'react';
import { useLocation } from 'react-router-dom';

function Success() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message = searchParams.get('message');
  const trxID = searchParams.get('trxID');
  const refundTrxID = searchParams.get('refundTrxID');

  return (
    <div className="App">
      <h1>{message && <p>{message}</p>}</h1>
      {trxID && <p>Your bKash Transaction ID: {trxID}</p>}
      {refundTrxID && <p>Your bKash Refund Transaction ID: {refundTrxID}</p>}
    </div>
  );
}

export default Success;
