import axios from "axios";

let ecomId = localStorage.getItem("ecomId");
var myAxios = axios.create({
  // baseURL: "http://localhost:4000",
  baseURL: "https://bapi.ecomtechbd.com",
  headers: { ecomId: ecomId },
});

export default myAxios;
