import React from "react";
import "./Pricing.css";
const Pricing = ({ data }) => {
  return (
    // <div className="pricing">
    //   <div className="container">
    //     <div className="pricing__container">
    //       <h1 className="pricing__container__title">Pricing</h1>
    //       <div className="pricing__cards__container">
    //         {data.courses.length > 0 &&
    //           data.courses.map((card) => (
    //             <PricingCard key={card.id} card={card} />
    //           ))}
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="landing1-price">
      {data.courses.length > 0 &&
        data.courses.map((data) => {
          console.log(data);
          return (
            <div className="landing1-price-box">
              <img src={data.src} />
              <div className="landing1-box-price">৳ {data.price}</div>
              <h2>{data.title.substring(0, 30)}</h2>

              <div></div>
            </div>
          );
        })}
    </div>
  );
};

const PricingCard = ({ card }) => {
  return (
    <div className="pricing__card__container">
      <h3>{card.title.substring(0, 25)}</h3>
      <h1>{card.price}</h1>
      <h3>{card.delivery_charge.substring(0, 25)}</h3>
    </div>
  );
};

export default Pricing;
