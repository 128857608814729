import React, { useEffect, useState, useContext } from "react";
import { Cartcontext } from "../../context/Context";
import "./HotCategory.css";

import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { motion } from "framer-motion";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function HotCategory({ data }) {
  const navigate = useNavigate();

  const [product, setProduct] = useState([]);
  const Globalstate = useContext(Cartcontext);

  const state = Globalstate.state;
  const dispatch = Globalstate.dispatch;

  useEffect(() => {
    axios
      .get(`${URL}/e-home-category-products/${data.cid}`)
      .then((res) => {
        setProduct(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const AddtoCart = (data) => {
    if (
      data._id === "6420774a076c3e2bdd58f2ae" ||
      data._id === "64207958076c3e2bdd58f2d9" ||
      data._id === "642b45e176572f46724dfe71" ||
      data._id === "64207fa2076c3e2bdd58f3e8"
    ) {
      navigate(`/product-new/64540211e776cdd487628b64`);
      return;
    }

    if (data.productPage === "2") {
      navigate(`/product-new/${data._id}`);
      return;
    }

    if (data.quantity <= 0) {
      return toast.error("Out Of Stock");
    }
    if (data.variationData.length > 0) {
      return navigate(`/products/${data._id}`);
    }

    const checkoutData = {
      id: data._id,
      title: data.productName,
      colorName: data.color,
      image:
        data.images[0] && data.images[0].url
          ? data.images[0].url
          : data.imgeUrl,
      price: data.proceSell,
      quantity: 1,
      VariationType: data.variation,
      gid: data._id,
      pCode: data.pCode,
    };

    toast.success("কার্ট-এ অ্যাড হয়েছে", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch({ type: "ADD", payload: checkoutData });
  };

  const BuyNow = (data) => {
    if (
      data._id === "6420774a076c3e2bdd58f2ae" ||
      data._id === "64207958076c3e2bdd58f2d9" ||
      data._id === "642b45e176572f46724dfe71" ||
      data._id === "64207fa2076c3e2bdd58f3e8"
    ) {
      navigate(`/product-new/64540211e776cdd487628b64`);
      return;
    }

    if (data.productPage === "2") {
      navigate(`/product-new/${data._id}`);
      return;
    }

    if (data.quantity <= 0) {
      return toast.error("Out Of Stock");
    }
    if (data.variationData.length > 0) {
      return navigate(`/products/${data._id}`);
    }
    const checkoutData = {
      id: data._id,
      title: data.productName,
      colorName: data.color,
      image:
        data.images[0] && data.images[0].url
          ? data.images[0].url
          : data.imgeUrl,
      price: data.proceSell,
      quantity: 1,
      VariationType: data.variation,
      gid: data._id,
      pCode: data.pCode,
    };
    dispatch({ type: "ADD", payload: checkoutData });
    navigate(`/checkout`);
  };

  return (
    <>
      <ToastContainer />
      {!data && (
        <div className="category-loading">
          <div className="category-loading-in">
            <ReactLoading
              type="cylon"
              color="rgb(226,115,29)"
              height={60}
              width={60}
            />
          </div>
        </div>
      )}

      {data && (
        <div className="Offer-Main-h">
          <div className="offer-deal-h">
            <h2 onClick={() => navigate(`/view-category/${data.cid}`)}>
              {data.cName}
            </h2>
            <h2
              onClick={() => navigate(`/view-category/${data.cid}`)}
            >{`SEE MORE >`}</h2>
          </div>

          <div className="Offer-Container">
            {product &&
              product.map((data, index) => {
                const title = data.productName.substring(0, 15) + "...";
                return (
                  <motion.div
                    className="Offer-Product"
                    key={index}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.7 }}
                  >
                    <img
                      src={
                        data.images[0] && data.images[0].url
                          ? data.images[0].url
                          : data.imgeUrl
                      }
                      alt={data.productName}
                      onClick={() => {
                        if (
                          data._id === "6420774a076c3e2bdd58f2ae" ||
                          data._id === "64207958076c3e2bdd58f2d9" ||
                          data._id === "642b45e176572f46724dfe71" ||
                          data._id === "64207fa2076c3e2bdd58f3e8"
                        ) {
                          navigate(`/product-new/64540211e776cdd487628b64`);
                          return;
                        }
                        if (data.productPage === "2") {
                          navigate(`/product-new/${data._id}`);
                          return;
                        }
                        navigate(`/products/${data._id}`);
                      }}
                    />

                    <div className="sale-out">
                      {data.quantity <= 0 ? "Out Of Stock" : "Sale"}
                    </div>
                    <div className="price-flex">
                      <p className="price-line">৳ {data.compare}</p>
                      <p className="price">৳ {data.proceSell}</p>
                    </div>

                    <h2
                      onClick={() => {
                        if (
                          data._id === "6420774a076c3e2bdd58f2ae" ||
                          data._id === "64207958076c3e2bdd58f2d9" ||
                          data._id === "642b45e176572f46724dfe71" ||
                          data._id === "64207fa2076c3e2bdd58f3e8"
                        ) {
                          navigate(`/product-new/64540211e776cdd487628b64`);
                          return;
                        }
                        if (data.productPage === "2") {
                          navigate(`/product-new/${data._id}`);
                          return;
                        }
                        navigate(`/products/${data._id}`);
                      }}
                    >
                      {title}
                    </h2>
                    <button
                      className="offer-addtocart"
                      onClick={() => AddtoCart(data)}
                    >
                      কার্ট-এ যোগ করুন
                    </button>

                    {data.showOrderNow && data.showOrderNow == "Yes" && (
                      <button
                        className="category-view-buynow"
                        onClick={() => BuyNow(data)}
                      >
                        অর্ডার করুন
                      </button>
                    )}
                  </motion.div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}

export default HotCategory;
