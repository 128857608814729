import React from "react";
import "./Suspend.css";
import suspendImage from "../../image/suspended.jpg";

function Suspend() {
  return (
    <div style={{ height: "70vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <img src={suspendImage} width={400} />
        {/* <h4 style={{ fontSize: "30px" }}>Contact the store owner</h4> */}
      </div>
    </div>
  );
}

export default Suspend;
