import React, { useEffect, useState, useContext } from "react";
import { Cartcontext } from "../../context/Context";
import BkashCreatePaymentAPICall from "../../action/BkashCreatePaymentAPICall";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./CheckOut.css";

import AnimatedInput from "../AnimatedInput/AnimatedInput";
import AnimatedInput1 from "../AnimatedInput/AnimatedInput1";
import AnimatedInput2 from "../AnimatedInput/AnimatedInput2";

import DuplicateOrder from "../../action/DuplicateOrder";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import myAxios from "../AxiosHeader";
import TagManager from "react-gtm-module";
import Bkash from "../../image/Bkash.png";
import Nagad from "../../image/Nagad.png";
import Rocket from "../../image/Rocket.png";
import COD from "../../image/COD.png";
import CreditCard from "../../image/Card.png";

function CheckOut() {
  const [store, setStore] = useState();
  const [cName, setName] = useState("");
  const [cMobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [CouponYes, setCouponYes] = useState(true);
  const [CouponBox, setCouponBox] = useState(false);
  const [CouponCode, setCouponCode] = useState("");

  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [Number, setNumber] = useState();
  const [DeliveryDhaka, setDeliveryDhaka] = useState();
  const [DeliveryOut, setDeliveryOut] = useState();
  const [privateNote, setPrivateNote] = useState("");
  const [SelectPayment, setSelectPayment] = useState("CASH ON DELIVERY");
  const navigate = useNavigate();

  // const orderUrl = "http://localhost:5000";
  const orderUrl = "https://order.ecomtechbd.com";

  const Globalstate = useContext(Cartcontext);
  const state = Globalstate.state;
  const dispatch = Globalstate.dispatch;

  const total = state.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);

  if (state > 0) {
    TagManager.dataLayer({
      dataLayer: {
        event: "checkout",
        ecommerce: {
          currencyCode: "BDT",
          checkout: {
            actionField: {
              step: 1,
              option: "Guest",
            },
            products: state,
          },
        },
      },
    });
  }

  const handlePurchase = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "purchase",
        ecommerce: {
          currencyCode: "BDT",
          purchase: {
            products: state,
          },
        },
      },
    });
  };

  DuplicateOrder(state);

  let Sfrom = 1;
  const ShipFrom = state.map((data) => {
    if (data.ShipFrom === "dhaka" || data.ShipFrom === "Dhaka") {
      return (Sfrom = 0);
    }
  });

  useEffect(() => {
    myAxios
      .get(`${URL}/e-store`)
      .then((res) => {
        setStore(res.data);
        setNumber(res.data.number);
        setDeliveryDhaka(res.data.Dhaka);
        setDeliveryOut(res.data.Outside);
      })
      .catch((err) => console.log(err.response.data));
  }, []);

  let orderData = state;
  const item = state.length ? state.length : 0;

  let Product_Code = [];
  state.map((data) => {
    var pData = {};
    pData["pCode"] = data.pCode;
    pData["color"] = data.colorName;
    pData["Quality"] = data.quantity;
    pData["Size"] = data.Size ? data.Size : "";
    Product_Code.push(pData);
  });

  const SubmitOrder = () => {
    setLoading(true);
    if (!state.length) {
      navigate("/");
    }

    if (!cName || !address) {
      toast.error("নাম ঠিকানা লিখুন");
      setLoading(false);
      return;
    }

    if (cMobile.length < 11 || cMobile.length > 11) {
      toast.error("১১ সংখ্যার মোবাইল নম্বর লিখুন");
      setLoading(false);
      return;
    }

    if (!location) {
      toast.error("ঢাকা সিটিতে না বাহিরে ? সিলেক্ট করুন ");
      setLoading(false);
      return;
    }

    const formData = {
      cName,
      cMobile,
      address,
      deliveryCharge: location,
      totalPrice: total,
      item,
      orderData,
      product: Product_Code,
      privateNote,
      Overseas: Sfrom,
      PaymentType: SelectPayment,
      TrafficSource: localStorage.getItem("TrafficSource"),
    };

    axios
      .post(`${orderUrl}`, formData)
      .then((res) => {
        handlePurchase();
        PixelEvent();

        PurchaseEvent({
          EventType: "PurchaseEvent",
        });

        let Invoice = res.data.invoiceId;
        let OrderId = res.data.id;
        if (res.data === "error") {
          toast.error(
            `অর্ডার করতে সমস্যা হয়েছে, এই নাম্বার এ কল দিন ${store.number} `
          );
          dispatch({ type: "RESET", payload: [] });
          setLoading(false);
        } else {
          dispatch({ type: "RESET", payload: [] });
          setLoading(false);
          if (SelectPayment == "BKASH") {
            BkashCreatePaymentAPICall(total, Invoice, OrderId);
          } else {
            navigate("/thanks");
          }
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let is_running = false;
  const PixelEvent = async () => {
    if (is_running == true) {
      return;
    }
    is_running = true;
    ReactPixel.track("Purchase", { currency: "BDT", value: total });
    is_running = false;
  };

  useEffect(() => {
    let is_running = false;

    if (is_running == true) {
      return;
    }

    is_running = true;
    ReactPixel.track("InitiateCheckout");

    is_running = false;
  }, []);

  const Pids = state.map((data) => data.id);

  useEffect(() => {
    if (total > 0) {
      CheckOutEvent({
        EventType: "CheckOutEvent",
        amount: total,
        content_ids: Pids,
      });
    }
  }, []);

  function CheckOutEvent(data) {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const source_url = `${window.location.origin}${location.pathname}`;
    const fbclid = new URLSearchParams(location.search).get("fbclid");
    const ecomId = localStorage.getItem("ecomId");
    const fbc = getCookie("_fbc");
    const fbp = getCookie("_fbp");

    const FromData = {
      eventType: data.EventType,
      fbp: fbp ? fbp : null,
      fbc: fbc ? fbc : null,
      fbclid: fbclid ? fbclid : null,
      content_ids: data.content_ids ? data.content_ids : "null",
      amount: data.amount ? data.amount : null,
      customerName: data.customerName ? data.customerName : null,
      mobileNumber: data.mobileNumber ? data.mobileNumber : null,
      source_url,
      ecomId,
    };

    myAxios
      .post("e-ConversionsAPI", FromData)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  function PurchaseEvent(data) {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const source_url = `${window.location.origin}${location.pathname}`;
    const fbclid = new URLSearchParams(location.search).get("fbclid");
    const ecomId = localStorage.getItem("ecomId");
    const fbc = getCookie("_fbc");
    const fbp = getCookie("_fbp");

    const FromData = {
      eventType: data.EventType,
      fbp: fbp ? fbp : null,
      fbc: fbc ? fbc : null,
      fbclid: fbclid ? fbclid : null,
      content_ids: Pids ? Pids : "null",
      amount: total ? total : null,
      customerName: cName ? cName : null,
      mobileNumber: cMobile ? cMobile : null,
      source_url,
      ecomId,
    };

    myAxios
      .post("e-ConversionsAPI", FromData)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {total
            ? `Total ৳ ${total} Plus Delivery Charge CheckOut`
            : "Thank you for your order"}
        </title>
      </Helmet>

      <div className="mainCheckout">
        <ToastContainer />
        <div className="checkout-container">
          <div className="checkout-left">
            <div className="mobile-section">
              <p>
                অর্ডার টি সম্পন্ন করতে আপনার নাম, মোবাইল নাম্বার ও ঠিকানা নিচে
                লিখুন
              </p>
              <div className="mobile-number-checkout">
                <AnimatedInput
                  type="text"
                  className="mobile-checkout"
                  name="name"
                  placeholder="এখানে আপনার নাম লিখুন"
                  value={cName}
                  onChange={(e) => setName(e.target.value)}
                />
                <AnimatedInput
                  type="number"
                  className="mobile-checkout"
                  name="mobile"
                  placeholder="১১ সংখ্যার মোবাইল নম্বর লিখুন"
                  value={cMobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
                <AnimatedInput1
                  className="mobile-checkout"
                  type="text"
                  name="address"
                  placeholder="আপনার ঠিকানা টি এখানে লিখুন"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>

              <div className="deliveryLocation">
                <FormControl onChange={(e) => setLocation(e.target.value)}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="dhaka"
                      control={<Radio />}
                      label={`ঢাকা সিটিতে ৳${DeliveryDhaka}`}
                      style={{ margin: "0px" }}
                    />
                    <FormControlLabel
                      value="outside"
                      control={<Radio />}
                      label={`সিটির বাহিরে ৳${DeliveryOut}`}
                      style={{ margin: "0px" }}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {CouponYes && (
                <div
                  style={{
                    border: "2px solid rgb(255 136 3 / 42%)",
                    padding: "8px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  Have Coupon ?{" "}
                  <span
                    style={{
                      cursor: "pointer",
                      borderRadius: "5px",
                      paddingLeft: "6px",
                      paddingRight: "6px",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                      marginLeft: "5px",
                      marginRight: "5px",
                      border: "1px solid rgb(27 55 78)",
                    }}
                    onClick={() => {
                      setCouponBox(true);
                      setCouponYes(false);
                    }}
                  >
                    YES
                  </span>
                </div>
              )}
              {CouponBox && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid rgb(255 136 3 / 42%)",
                    margin: "10px",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div className="couponLeft">
                    <AnimatedInput
                      type="text"
                      className="mobile-checkout"
                      name="name"
                      placeholder="কোড লিখে সাবমিট করুন"
                      value={cName}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <button
                    style={{
                      padding: "8px",
                      backgroundColor: "rgb(23, 53, 30)",
                      color: "#fff",
                      fontSize: "18px",
                      border: "none",
                      borderRadius: "8px",
                      cursor: "pointer",
                      marginLeft: "10px",
                    }}
                  >
                    সাবমিট কুপন
                  </button>
                  <button
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "5px",
                      height: "25px",
                      width: "25px",
                      borderRadius: "50%",
                      border: "2px solid rgb(255 136 3 / 42%)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCouponBox(false);
                      setCouponYes(true);
                    }}
                  >
                    X
                  </button>
                </div>
              )}

              {/* <div className="Payment">
                <div className="Payment-Message">Pay With Mobile Banking</div>
                <div className="Payment-Getway">
                  <div
                    className="Getway-Option"
                    onClick={() => setSelectPayment("CASH ON DELIVERY")}
                  >
                    <img
                      className={
                        SelectPayment == "CASH ON DELIVERY"
                          ? "Getway-Option-active"
                          : "Getway-Option-img"
                      }
                      src={COD}
                      alt="CASH ON DELIVERY"
                    />
                  </div>
                  <div
                    className="Getway-Option"
                    onClick={() => setSelectPayment("BKASH")}
                  >
                    <img
                      className={
                        SelectPayment == "BKASH"
                          ? "Getway-Option-active"
                          : "Getway-Option-img"
                      }
                      src={Bkash}
                      alt="BKASH"
                    />
                  </div>
                  <div
                    className="Getway-Option"
                    onClick={() => setSelectPayment("NAGAD")}
                  >
                    <img
                      className={
                        SelectPayment == "NAGAD"
                          ? "Getway-Option-active"
                          : "Getway-Option-img"
                      }
                      src={Nagad}
                      alt="NAGAD"
                    />
                  </div>
                  <div
                    className="Getway-Option"
                    onClick={() => setSelectPayment("ROCKET")}
                  >
                    <img
                      className={
                        SelectPayment == "ROCKET"
                          ? "Getway-Option-active"
                          : "Getway-Option-img"
                      }
                      src={Rocket}
                      alt="ROCKET"
                    />
                  </div>
                  <div
                    className="Getway-Option"
                    onClick={() => setSelectPayment("CREDIT CARD")}
                  >
                    <img src={CreditCard} alt="CREDIT CARD" />
                  </div>
                </div>
                <div className="Payment-Message">
                  <h5>
                    {SelectPayment} : সিলেক্ট করা আছে অর্ডার বাটন এ ক্লিক করুন
                  </h5>
                </div>
              </div> */}

              {/* <div className="Payment">
                <div className="Payment-Message">
                  অর্ডার টি কনফার্ম করতে ১০০ টাকা বিকাশে অগ্রিম পেমেন্ট করুন
                  ধন্যবাদ
                </div>
                <div className="Payment-Getway">
                  <div
                    className="Getway-Option"
                    onClick={() => setSelectPayment("BKASH")}
                  >
                    <img
                      className={
                        SelectPayment == "BKASH"
                          ? "Getway-Option-active"
                          : "Getway-Option-img"
                      }
                      src={Bkash}
                      alt="BKASH"
                    />
                  </div>
                </div>
                <div className="Payment-Message">
                  <h5>
                    {SelectPayment} : সিলেক্ট করা আছে অর্ডার বাটন এ ক্লিক করুন
                  </h5>
                </div>
              </div> */}

              <button className="call-button-checkout" onClick={SubmitOrder}>
                {loading ? "Loading.." : "অর্ডার করুন"}
              </button>

              <AnimatedInput2
                className="privateNote"
                type="text"
                name="note"
                placeholder="অর্ডার নোট থাকলে এখানে লিখুন"
                value={privateNote}
                onChange={(e) => setPrivateNote(e.target.value)}
              />
            </div>
          </div>

          <div className="checkout-right">
            <div className="card-data-section">
              <div className="Total-Cart">
                <div className="total-item-">
                  Total ৳{" "}
                  {location == "dhaka"
                    ? total + DeliveryDhaka * 1
                    : total + DeliveryOut * 1}
                </div>
                <div className="total-item-">Cart Item {state.length}</div>
              </div>
              {/* <div className="checkout-head">
                <div>Photo</div>
                <div>Color</div>
                <div>Size</div>
                <div>Taka</div>
                <div>Quantity</div>
                <div>Remove</div>
              </div> */}
              {state.length <= 0 && <div className="no-cart-data">No data</div>}
              {state?.map((data, index) => {
                return (
                  <div className="cart-item" key={index}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          borderBottom: "1px solid #ccc",
                          textAlign: "center",
                          padding: "5px",
                        }}
                      >
                        {data.colorName}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={data.image} alt={data.colorName} />
                        <h2>{data.Size && data.Size}</h2>
                        <h2>৳ {data.price}</h2>
                        <div className="cart-input-container">
                          <button
                            className="cart-button"
                            onClick={() => {
                              if (data.quantity > 1) {
                                dispatch({ type: "DECREASE", payload: data });
                              } else {
                                return;
                              }
                            }}
                          >
                            -
                          </button>
                          <input
                            className="cart-input"
                            type="text"
                            name="cartinput"
                            value={data.quantity}
                          />
                          <button
                            className="cart-button"
                            onClick={() => {
                              if (data.quantity >= data.Available) {
                                toast.error("Quantity limit");
                              } else {
                                dispatch({ type: "INCREASE", payload: data });
                              }
                            }}
                          >
                            +
                          </button>
                        </div>
                        <button
                          className="cart-remove"
                          onClick={() => {
                            dispatch({ type: "REMOVE", payload: data });
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckOut;
