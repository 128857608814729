import React, { useEffect, useState, useContext } from "react";
import { Cartcontext } from "../../context/Context";
import "./DealView.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";
import ReactLoading from "react-loading";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

function ViewCategory() {
  const [reactLoading, setReactLoading] = useState(true);
  let { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const Globalstate = useContext(Cartcontext);
  const state = Globalstate.state;
  const dispatch = Globalstate.dispatch;
  const [store, setStore] = useState();

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const heightData = screenSize.height <= 500 ? "100vh" : "100%";

  const updateScreenSize = () => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get(`${URL}/e-store`)
      .then((res) => setStore(res.data))
      .catch((err) => console.log(err.response.data));
  }, []);

  useEffect(() => {
    axios
      .get(`${URL}/e-deal-view/${id}`)
      .then((res) => {
        setProduct(res.data);
        if (res.data === "No product found..") {
        }
        setReactLoading(false);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const AddtoCart = (data) => {
    if (
      data.id === "6420774a076c3e2bdd58f2ae" ||
      data.id === "64207958076c3e2bdd58f2d9" ||
      data.id === "642b45e176572f46724dfe71" ||
      data.id === "64207fa2076c3e2bdd58f3e8"
    ) {
      navigate(`/product-new/64540211e776cdd487628b64`);
      return;
    }

    if (data.productPage === "2") {
      navigate(`/product-new/${data.id}`);
      return;
    }

    if (data.quantity <= 0) {
      return toast.error("Out Of Stock");
    }
    if (data.variationData.length > 0) {
      return navigate(`/products/${data.id}`);
    }

    const checkoutData = {
      id: data.id,
      title: data.name,
      colorName: data.colorName,

      image:
        data.images[0] && data.images[0].url ? data.images[0].url : data.imgUrl,

      price: data.proceSell,
      quantity: 1,
      VariationType: data.variation,
      gid: data.id,
    };

    toast.success("কার্ট-এ অ্যাড হয়েছে", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch({ type: "ADD", payload: checkoutData });
  };

  const BuyNow = (data) => {
    if (
      data.id === "6420774a076c3e2bdd58f2ae" ||
      data.id === "64207958076c3e2bdd58f2d9" ||
      data.id === "642b45e176572f46724dfe71" ||
      data.id === "64207fa2076c3e2bdd58f3e8"
    ) {
      navigate(`/product-new/64540211e776cdd487628b64`);
      return;
    }

    if (data.productPage === "2") {
      navigate(`/product-new/${data.id}`);
      return;
    }
    if (data.quantity <= 0) {
      return toast.error("Out Of Stock");
    }
    if (data.variationData.length > 0) {
      return navigate(`/products/${data.id}`);
    }
    const checkoutData = {
      id: data.id,
      title: data.name,
      colorName: data.colorName,

      image:
        data.images[0] && data.images[0].url ? data.images[0].url : data.imgUrl,

      price: data.proceSell,
      quantity: 1,
      VariationType: data.variation,
      gid: data.id,
    };
    dispatch({ type: "ADD", payload: checkoutData });
    navigate(`/checkout`);
  };

  return (
    <div style={{ height: heightData }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {store?.domain ? store.domain.toUpperCase() : ""} || Category
        </title>
      </Helmet>
      <ToastContainer />
      {!reactLoading && !product.length && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background:
              "linear-gradient(90deg, rgba(226, 115, 29, 0.05) 0%, rgba(235, 99, 28, 0.16) 100%)",
            paddingTop: "20px",
            color: "rgb(25, 24, 24)",
            fontSize: "40px",
          }}
        >
          No Product Found
        </div>
      )}
      {reactLoading && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
            transition: "1s",
          }}
        >
          <ReactLoading
            type="spin"
            color="rgb(226,115,29)"
            height={60}
            width={60}
          />
        </div>
      )}
      {product.length && (
        <div className="category-view-Main">
          <div className="category-view-Container">
            {product &&
              product.map((data, index) => {
                const title = data.name.substring(0, 40) + "...";
                return (
                  <motion.div
                    className="category-view-Product"
                    key={index}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.6 }}
                  >
                    <img
                      src={
                        data.images[0] && data.images[0].url
                          ? data.images[0].url
                          : data.imgUrl
                      }
                      alt={data.name}
                      onClick={() => {
                        if (
                          data.id === "6420774a076c3e2bdd58f2ae" ||
                          data.id === "64207958076c3e2bdd58f2d9" ||
                          data.id === "642b45e176572f46724dfe71" ||
                          data.id === "64207fa2076c3e2bdd58f3e8"
                        ) {
                          navigate(`/product-new/64540211e776cdd487628b64`);
                          return;
                        }
                        if (data.productPage === "2") {
                          navigate(`/product-new/${data.id}`);
                          return;
                        }
                        navigate(`/products/${data.id}`);
                      }}
                    />
                    <div className="category-view-sale-out">
                      {data.quantity <= 0 ? "Out Of Stock" : "Sale"}
                    </div>
                    <div className="category-view-price-flex">
                      <p className="category-view-price-line">
                        ৳ {data.compare}
                      </p>
                      <p className="category-view-price">৳ {data.proceSell}</p>
                    </div>

                    <h2
                      onClick={() => {
                        if (
                          data.id === "6420774a076c3e2bdd58f2ae" ||
                          data.id === "64207958076c3e2bdd58f2d9" ||
                          data.id === "642b45e176572f46724dfe71" ||
                          data.id === "64207fa2076c3e2bdd58f3e8"
                        ) {
                          navigate(`/product-new/64540211e776cdd487628b64`);
                          return;
                        }
                        if (data.productPage === "2") {
                          navigate(`/product-new/${data.id}`);
                          return;
                        }
                        navigate(`/products/${data.id}`);
                      }}
                    >
                      {title}
                    </h2>
                    <button
                      className="category-view-addtocart"
                      onClick={() => AddtoCart(data)}
                    >
                      কার্ট-এ যোগ করুন
                    </button>
                    {data.showOrderNow && data.showOrderNow == "Yes" && (
                      <button
                        className="category-view-buynow"
                        onClick={() => BuyNow(data)}
                      >
                        অর্ডার করুন
                      </button>
                    )}
                  </motion.div>
                );
              })}
          </div>
          {product.length > 9 && (
            <div className="Offer-Pagination">
              <Stack spacing={2}>
                <Pagination count={10} color="primary" />
              </Stack>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ViewCategory;
