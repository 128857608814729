import React from "react";
import { FeatureCard } from "../utils/Cards";
import Slider from "../utils/Slider";

const Features = ({ data }) => {
  return (
    <div className="features__container">
      <div className="container">
        <div className="features__card__container">
          <FeatureCard data={data} />
          <div className="feature__image__slider">
            <Slider>
              {data.SubSliders.length > 0 &&
                data.SubSliders.map((image, index) => (
                  <img key={image.id} src={image.src} />
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
