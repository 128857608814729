import React, { useState, useEffect } from "react";
import { Input } from "../utils/Input";
import PackageCard from "../utils/PackageCard";
import { IoMdLock } from "react-icons/io";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// const orderUrl = "http://localhost:5000/l1";
const orderUrl = "https://order.ecomtechbd.com/l1";

export const Form = ({ data, scrollRef }) => {
  const [selectedCard, setSelectedCard] = useState(data.courses[0]);
  const [cartData, setCartData] = useState(data.courses[0]);
  const [loading, setLoading] = useState(false);

  const [selectedShipping, setSelectedShipping] = useState("dhaka");
  const navigate = useNavigate();

  const calculateTotalShipping = () => {
    const subtotal = Number(convertBengaliToEnglish(cartData.price));

    let DeliveryCharge;
    if (selectedShipping === "dhaka") {
      DeliveryCharge = data.DeliveryCharge.insideDhaka;
    }
    if (selectedShipping === "outside") {
      DeliveryCharge = data.DeliveryCharge.outsideDhaka;
    }

    const DeliveryChargeConverted = Number(
      convertBengaliToEnglish(DeliveryCharge)
    );

    const totalPrice = subtotal + DeliveryChargeConverted;
    return convertToBanglaNumber(totalPrice);
  };

  const TotalAmount = (selectedShippingInput) => {
    const subtotal = Number(convertBengaliToEnglish(cartData.price));

    let DeliveryCharge;
    if (selectedShippingInput === "dhaka") {
      DeliveryCharge = data.DeliveryCharge.insideDhaka;
    }
    if (selectedShippingInput === "outside") {
      DeliveryCharge = data.DeliveryCharge.outsideDhaka;
    }

    const DeliveryChargeConverted = Number(
      convertBengaliToEnglish(DeliveryCharge)
    );

    const totalPrice = subtotal + DeliveryChargeConverted;
    return { totalPrice, DeliveryChargeConverted, subtotal };
  };

  const handleCardSelect = (card) => {
    setSelectedCard(card);
    setCartData(card);
  };

  const handleShippingChange = (event) => {
    setSelectedShipping(event.target.value);

    const newFormData = {
      ...formData,
      selectedShipping: event.target.value,
      TotalAmount: TotalAmount(event.target.value),
    };

    setFormData(newFormData);
  };

  function convertBengaliToEnglish(bengaliNumber) {
    const bengaliDigits = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];

    const englishNumber = bengaliNumber.replace(/[০-৯]/g, function (match) {
      return bengaliDigits.indexOf(match).toString();
    });

    return englishNumber;
  }

  function convertToBanglaNumber(englishNumber) {
    const banglaDigits = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];

    const convertDigit = (digit) => {
      return banglaDigits[parseInt(digit)];
    };

    return englishNumber.toString().replace(/\d/g, convertDigit);
  }

  const [formData, setFormData] = useState({
    cName: "",
    address: "",
    cMobile: "",
    selectedCard,
    selectedShipping,
    TotalAmount: TotalAmount("dhaka"),
    uid: data.uid,
    pageId: data._id,
    TrafficSource: localStorage.getItem("TrafficSource"),
  });

  function formHandler(event) {
    event.preventDefault();
    setLoading(true);
    axios
      .post(orderUrl, formData)
      .then((res) => {
        navigate("/thanks");
      })
      .catch((err) => console.log(err.response.data));
  }

  return (
    <div className="form" ref={scrollRef}>
      <div className="container">
        <div className="form__row">
          <p style={{ textAlign: "center" }}>
            কোন প্যাকেজটি নিতে চান সিলেক্ট করুন
          </p>
          <div className="form__package__container">
            {data.courses.map((card) => (
              <PackageCard
                key={card.id}
                card={card}
                isSelected={card.id === selectedCard?.id}
                onSelect={handleCardSelect}
              />
            ))}
          </div>
        </div>
        <div className="form__container">
          <form onSubmit={formHandler}>
            <h2>
              অর্ডার করতে আপনার সঠিক তথ্য দিয়ে নিচের ফর্মটি সম্পূর্ণ পূরন করুন।
              (আগে থেকে কোন টাকা দেয়া লাগবে না। প্রোডাক্ট হাতে পাবার পর টাকা
              দিবেন)
            </h2>
            <div className="form__container__group">
              <div className="form__col">
                <div className="form__row">
                  <p>Billing Details</p>
                  <Input
                    label="আপনার নাম লিখুন"
                    id="cName"
                    name="cName"
                    value={formData.cName}
                    onChange={(e) =>
                      setFormData({ ...formData, cName: e.target.value })
                    }
                    placeholder="আপনার নাম"
                  />
                  <Input
                    label="আপনার ঠিকানা লিখুন "
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={(e) =>
                      setFormData({ ...formData, address: e.target.value })
                    }
                    placeholder="বাসা নং, রোড নং, থানা, জেলা"
                  />
                  <Input
                    label="আপনার মোবাইল নাম্বারটি লিখুন"
                    id="mobile"
                    name="cMobile"
                    value={formData.cMobile}
                    onChange={(e) =>
                      setFormData({ ...formData, cMobile: e.target.value })
                    }
                    pattern="^\d{11}$"
                    title="Mobile number should be 11 digits long"
                    placeholder="মোবাইল নাম্বার"
                  />
                </div>
              </div>
              <div className="form__col">
                <div className="form__row">
                  <p>Your Order</p>
                  <div className="cart">
                    <div className="cart__container bold__text">
                      <div className="cart__container__left">Product</div>
                      <div className="cart__container__right">Subtotal</div>
                    </div>
                    <div className="cart__container">
                      <div className="cart__container__left">
                        <div className="cart__container__image">
                          <img src={cartData.src} alt="cart__image" />
                        </div>
                        <div className="cart__container__left__content">
                          <div className="cart__product__name">
                            {cartData.title}
                          </div>
                          <div className="cart__product__quantity">X ১</div>
                        </div>
                      </div>
                      <div className="cart__container__right">
                        ৳ {cartData.price}
                      </div>
                    </div>
                    <div className="cart__container no__border__bottom">
                      <div className="cart__container__left">Subtotal</div>
                      <div className="cart__container__right">
                        ৳ {cartData.price}
                      </div>
                    </div>
                    <div className="cart__container">
                      <div className="cart__container__left">Shipping</div>
                      <div className="cart__container__right">
                        <div className="cart__shipping__methods">
                          <label>
                            <input
                              type="radio"
                              value="dhaka"
                              checked={selectedShipping === "dhaka"}
                              onChange={handleShippingChange}
                            />{" "}
                            ঢাকার ভিতরে: ৳{" "}
                            {convertToBanglaNumber(
                              data.DeliveryCharge.insideDhaka
                            )}
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="outside"
                              checked={selectedShipping === "outside"}
                              onChange={handleShippingChange}
                            />{" "}
                            ঢাকার বাহিরে: ৳{" "}
                            {convertToBanglaNumber(
                              data.DeliveryCharge.outsideDhaka
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="cart__container bold__text no__border__bottom">
                      <div className="cart__container__left">Total</div>
                      <div className="cart__container__right">
                        ৳ {calculateTotalShipping()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form__row">
                  <div className="form__note__content">
                    <div>ক্যাশ অন ডেলিভারি</div>
                    <div className="form__note">
                      পণ্য হাতে পেয়ে ডেলিভারি ম্যানকে পেমেন্ট করতে পারবেন।
                    </div>
                  </div>
                  <div className="form__privacy__text">
                    Your personal data will be used to process your order,
                    support your experience throughout this website, and for
                    other purposes described in our privacy policy.
                  </div>
                  <button type="submit">
                    <IoMdLock />
                    <div>
                      {loading
                        ? "অপেক্ষা করুন.."
                        : `Place Order ৳ ${calculateTotalShipping()}`}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </form>
          {/* <h2 className="form__contact__number">
            কোন প্রশ্ন বা সাহায্য লাগলে কল করুনঃ 0172087***
          </h2> */}
        </div>
      </div>
    </div>
  );
};
