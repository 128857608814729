import React, { useState } from "react";
import BkashCreatePaymentAPICall from "../../action/BkashCreatePaymentAPICall";
import ErrorMessage from "./ErrorMessage";

function Home() {

  const [amount, setAmount] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
 
  const handlePaymentClick = () => {
    if (isNaN(amount) || +amount <= 0) {
      setErrorMessage("Please enter a valid amount.");
    } else {
      BkashCreatePaymentAPICall(amount);
    }
  };

  return (
    <div className="App">
        <main className="main">
          <section className="payment-section">
            <h2 className="payment-header">Make a Payment</h2>
            <div className="input-container">
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="small-amount-input"
                placeholder="Enter amount"
              />

              <br></br>
              <br></br>
              <button onClick={handlePaymentClick} className="payment-button">
                Pay With bKash
              </button>
            </div>
          </section>

          {errorMessage && <ErrorMessage message={errorMessage} />}

        </main>
    </div>
  );
}

export default Home;
