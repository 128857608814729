import myAxios from "../AxiosHeader";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ConversionsAPI(data) {
  const location = useLocation();

  useEffect(() => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const source_url = `${window.location.origin}${location.pathname}`;
    const fbclid = new URLSearchParams(location.search).get("fbclid");
    const ecomId = localStorage.getItem("ecomId");
    const fbc = getCookie("_fbc");
    const fbp = getCookie("_fbp");

    const FromData = {
      eventType: data.EventType,
      fbp: fbp ? fbp : null,
      fbc: fbc ? fbc : null,
      fbclid: fbclid ? fbclid : null,
      amount: data.amount ? data.amount : null,
      customerName: data.customerName ? data.customerName : null,
      mobileNumber: data.mobileNumber ? data.mobileNumber : null,
      source_url,
      ecomId,
    };

    myAxios
      .post("e-ConversionsAPI", FromData)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }, [location.pathname]);
}

export default ConversionsAPI;
