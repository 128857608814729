import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "./Slider.css";

export const CategorySlider = ({ category }) => {
  const navigate = useNavigate();

  const breakpoints = {
    1024: {
      slidesPerView: 5,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    300: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
  };

  return (
    <Swiper
      // modules={[Navigation, Pagination, Autoplay]}
      // navigation
      // pagination={{ clickable: true }}
      // breakpoints={breakpoints}
      // autoplay={{ delay: 1000, disableOnInteraction: false }}
      // loop={true}
      modules={[Navigation, Pagination, Autoplay]}
      slidesPerView={1}
      breakpoints={breakpoints}
      autoplay={{ delay: 2000, disableOnInteraction: false }}
      loop={true}
      className="category-slider"
    >
      {category &&
        category.map((item, index) => {
          return (
            <SwiperSlide>
              <div
                className="category-box-h3"
                key={index}
                onClick={() => navigate(`/view-category/${item.id}`)}
              >
                <div className="category-box">
                  <img src={item.imgUrl} alt="category" />
                </div>
                <h3>{item.name}</h3>
              </div>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export const HeroSlider = ({ heroSlices }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      navigation
      pagination={{ clickable: true }}
      slidesPerView={1}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      loop={true}
      className="hero-slider"
    >
      {heroSlices &&
        heroSlices?.map((item) => {
          return (
            <SwiperSlide>
              <div key={item.id} className="hero-slider-container">
                <Link to={item.url}>
                  <img src={item.image} alt="hero-image" />
                </Link>
              </div>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export const ProductSlider = ({ data }) => {
  return (
    <Swiper
      pagination={{ clickable: true }}
      modules={[Navigation, Pagination, Autoplay]}
      slidesPerView={1}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      loop={true}
    >
      {data &&
        data?.map((item) => {
          if (item.url) {
            return (
              <SwiperSlide key={item.id}>
                <img src={item.url} alt="productimage" />
              </SwiperSlide>
            );
          }
        })}
    </Swiper>
  );
};

export const ProductCategorySlider = ({ data }) => {
  const navigate = useNavigate();

  const breakpoints = {
    350: {
      slidesPerView: 3,
      spaceBetween: 60,
    },
    300: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
  };

  return (
    <Swiper
      modules={[Navigation, Autoplay]}
      navigation
      pagination={{ clickable: true }}
      breakpoints={breakpoints}
      autoplay={{ delay: 500, disableOnInteraction: false }}
      loop={true}
      // modules={[Navigation, Pagination, Autoplay]}
      // slidesPerView={1}
      // breakpoints={breakpoints}
      // autoplay={{ delay: 2000, disableOnInteraction: false }}
      // loop={true}
      className="category-slider"
    >
      {data &&
        data.map((item, index) => {
          return (
            <SwiperSlide>
              <div
                className="category-box-h3"
                key={index}
                onClick={() => navigate(`/view-category/${item.id}`)}
              >
                <div className="category-box">
                  <img src={item.imgUrl} alt="category" />
                </div>
                <h3>{item.name}</h3>
              </div>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};
