import React from "react";

function DuplicateOrder(state) {

  if(!localStorage.getItem("LastOrder")){
    localStorage.setItem("LastOrder", JSON.stringify(state))
  }
  console.log("localStorage.getItem", localStorage.getItem("State"))


}

export default DuplicateOrder;
