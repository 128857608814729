import { useEffect, useState } from "react";
import "./Footer.css";
import axios from "axios";

import myAxios from "../AxiosHeader";
import { useNavigate } from "react-router-dom";
import { URL } from "../AxiosUrlGlobal";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { AiFillYoutube } from "react-icons/ai";
function Footer() {
  const navigate = useNavigate();
  const [store, setStore] = useState();
  const [Category, setCategory] = useState([]);

  useEffect(() => {
    myAxios
      .get(`${URL}/e-store`)
      .then((res) => {
        setStore(res.data);
      })
      .catch((err) => console.log(err.response.data));
  }, []);

  useEffect(() => {
    axios
      .get(`${URL}/e-category`)
      .then((res) => {
        setCategory(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const d = new Date();
  let year = d.getFullYear();
  return (
    <>
      <div className="footer-Top">
        <div className="footer-Top-box">
          <h3>BEST CATEGORIES</h3>
          <ul>
            {Category &&
              Category.map((data) => {
                return (
                  <li
                    key={data.id}
                    onClick={() => navigate(`/view-category/${data.id}`)}
                  >
                    {data.name}
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="footer-Top-box">
          <h3>ContactUs</h3>
          <ul>
            <li>{store ? store.store : ""}</li>
            <li>Address: {store ? store.Address : ""}</li>
            <li>
              Email Us:{" "}
              <a href={`mailto:${store ? `${store.Email}` : ""}`}>
                {store ? `${store.Email}` : ""}
              </a>{" "}
            </li>
            <li>
              Call Us:{" "}
              <a href={`tel:${store ? store.number : ""}`}>
                {store ? store.number : ""}
              </a>{" "}
            </li>
          </ul>
        </div>
        <div className="footer-Top-box">
          <h3>Follow Us</h3>
          <ul>
            <li>
              <BsFacebook />{" "}
              <a target="blank" href={store ? store.FacebookUrl : ""}>
                FaceBook
              </a>
            </li>
            <li>
              <AiFillYoutube />{" "}
              <a target="blank" href={store ? store.YouTubeUrl : ""}>
                YouTube
              </a>
            </li>
            <li>
              <BsInstagram />{" "}
              <a target="blank" href={store ? store.InstagramUrl : ""}>
                Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer">
        <div className="deDesign-and-Developeds">
          Copyright {year} ©{" "}
          <a
            style={{ color: "#fff", fontSize: "19px" }}
            href={`https://${store ? store.domain : ""}`}
            target="blank"
          >
            {store ? store.domain : ""}
          </a>{" "}
        </div>
      </div>
      <div className="footer-credit">
        <div
          style={{
            fontSize: "12px",
            textAlign: "center",
            paddingBottom: "4px",
            paddingTop: "1px",
          }}
        >
          Design and Developed by{" "}
          <a
            style={{ color: "rgb(70 224 55)" }}
            href="https://ecomtechbd.com"
            target="blank"
          >
            EcomTechBD
          </a>{" "}
          Using React Js, Node Js, MongoDB, Express, Redis
        </div>
      </div>
    </>
  );
}

export default Footer;
