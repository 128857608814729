import React from "react";
import TextItem from "../utils/TextItem";
import { FiArrowRightCircle } from "react-icons/fi";

export const EffectivenessContentList = ({ data }) => {
  return (
    <div className="effectiveness__content__list">
      <div className="container">
        <div className="effectiveness__content__list__container">
          <div className="effectiveness__content__list__card">
            {data.Pros.length > 0 &&
              data.Pros.map((item) => (
                <TextItem
                  key={item.id}
                  icon={<FiArrowRightCircle className="icon" />}
                  text={item.text}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
