import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { HeroSlider } from "../Slider/Slider";
import { URL } from "../AxiosUrlGlobal";
import axios from "axios";

const Banner = () => {
  const [loadingSlider, setLoadingSlider] = useState(false);
  const [SliderData, setSliderData] = useState();
  useEffect(() => {
    axios
      .get(`${URL}/e-banner`)
      .then((res) => {
        setSliderData(res.data.Data);
      })
      .catch((error) => console.log(error.response.data));
  }, []);

  return (
    <>
      {loadingSlider ? (
        <div className="category-loading">
          <div className="category-loading-in">
            <ReactLoading
              type="spin"
              color="rgb(226,115,29)"
              height={60}
              width={60}
            />
          </div>
        </div>
      ) : (
        <HeroSlider heroSlices={SliderData} />
      )}
    </>
  );
};

export default Banner;
