import axios from "axios";

export default function BkashRefundPaymentAPICall(paymentID, trxID, amount) {
  return new Promise((resolve, reject) => {
    axios
      .post("https://bapi.ecomtechbd.com/api/bkash/refund", {
        paymentID: paymentID,
        trxID: trxID,
        amount: amount,
        sku: "test",
        reason: "test",
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("An error occurred:", error);
        reject(error);
      });
  });
}
