import React, { useState } from "react";
import TextItem from "../utils/TextItem";
import { PrimaryButton } from "../utils/Button";
import OrderModal from "../components/Modal/OrderModal";
const Decisions = ({ data }) => {
  const [modal, setModal] = useState(false);
  return (
    <>
      {modal && <OrderModal setModal={setModal} data={data} />}
      <div className="decisions">
        <div className="container">
          <div className="decisions__container">
            <h1>সিদ্ধান্ত</h1>
            {data.Decisions.length > 0 &&
              data.Decisions.map((item) => (
                <TextItem key={item.id} text={item.text} />
              ))}
            <PrimaryButton text={"buy now"} handler={setModal} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Decisions;
