import { useEffect } from "react";
import { URL } from "../AxiosUrlGlobal";
import myAxios from "../AxiosHeader";

function Suspend(id) {
  useEffect(() => {
    myAxios
      .get(`${URL}/e-store`)
      .then((res) => {})
      .catch((err) => console.log(err.response.data));
  }, []);
}

export default Suspend;
