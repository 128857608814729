import React, { useState } from "react";
import BkashRefundPaymentAPICall from "../../action/BkashRefundPaymentAPICall";
import ErrorMessage from "./ErrorMessage";

function Refund() {
  const [paymentID, setPaymentID] = useState("");
  const [trxID, setTrxID] = useState("");
  const [amount, setAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleRefundPaymentClick = async () => {
    setErrorMessage(""); // Clear previous error messages

    if (
      paymentID.trim() === "" ||
      trxID.trim() === "" ||
      amount.trim() === ""
    ) {
      setErrorMessage("All fields are required.");
    } else if (isNaN(amount) || +amount <= 0) {
      setErrorMessage("Please enter a valid amount.");
    } else {
      try {
        const response = await BkashRefundPaymentAPICall(
          paymentID,
          trxID,
          amount
        );
        console.log(response)
        if (response?.refundTrxID) {
          window.location.href = `/success?message=${response?.transactionStatus}&trxID=${response?.originalTrxID}&refundTrxID=${response?.refundTrxID}`;
        } else {
          window.location.href = `/fail?message=${response?.statusMessage}`;
        }
      } catch (error) {
        setErrorMessage("An error occurred during the refund process.");
      }
    }
  };

  return (
    <div className="refund-container">
      <main className="main">
        <section className="payment-section">
          <h2 className="payment-header">bKash Refund</h2>

          <div className="input-container">
            <input
              type="text"
              value={paymentID}
              onChange={(e) => setPaymentID(e.target.value)}
              placeholder="Enter paymentID"
              className="input-field"
            />
            <br></br>
            <br></br>

            <input
              type="text"
              value={trxID}
              onChange={(e) => setTrxID(e.target.value)}
              placeholder="Enter trxID"
              className="input-field"
            />
            <br></br>
            <br></br>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="small-amount-input input-field"
              placeholder="Enter amount"
            />
            <br></br>
            <br></br>

            <button
              onClick={handleRefundPaymentClick}
              className="payment-button"
            >
              Refund
            </button>
          </div>
        </section>

        {errorMessage && <ErrorMessage message={errorMessage} />}
      </main>
    </div>
  );
}

export default Refund;
