import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import Video from "./Video";
import Faq from "./Faq";
import Pricing from "./Pricing";
import { Review } from "./Review";
import { Form } from "./Form";
import axios from "axios";
import { URL } from "../../AxiosUrlGlobal";
import ReactLoading from "react-loading";

import "../landing-2.css";

const Landing1Home = () => {
  const scrollRef = useRef();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    axios
      .get(`${URL}/get-landing-front/${id}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const srollToOrder = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {loading && (
        <div
          style={{
            width: "100vw",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
            transition: "1s",
          }}
        >
          <ReactLoading
            type="spin"
            color="rgb(226,115,29)"
            height={60}
            width={60}
          />
        </div>
      )}

      {error && (
        <div
          style={{
            width: "100vw",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
            transition: "1s",
          }}
        >
          <div>404 Page not Found</div>
        </div>
      )}
      {!loading && !error && (
        <div className="landing-page-main-container">
          <Video data={data} srollToOrder={srollToOrder} />
          <Faq data={data} />
          <Pricing data={data} />
          <Review data={data} />
          <Form data={data} scrollRef={scrollRef} />
        </div>
      )}
    </>
  );
};

export default Landing1Home;
