import React from "react";
import { DescriptionCard } from "../utils/Cards";
import ImageCard from "../utils/ImageCard";
import Slider from "../utils/Slider";

const Description = ({ data }) => {
  return (
    <div className="description">
      <div className="container">
        <div className="description__container">
          <div className="description__image">
            <ImageCard src={data.HeroImage} />
            <div className="description__image__slider">
              <Slider>
                {data.SubSliders.length > 0 &&
                  data.SubSliders.map((image, index) => (
                    <img key={image.id} src={image.src} />
                  ))}
              </Slider>
            </div>
          </div>
          <div className="description__text">
            <DescriptionCard data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
