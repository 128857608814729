import React from "react";

const PackageCard = ({ card, isSelected, onSelect }) => {
  return (
    <div
      style={{
        backgroundColor: "#008037",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        borderRadius: "10px",
      }}
      onClick={() => onSelect(card)}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ padding: "10px" }}>
          <input type="radio" checked={isSelected} />
        </div>
        <h3 style={{ color: "#fff", fontSize: "14px" }}>
          {" "}
          {card.title.substring(0, 30)}
        </h3>
      </div>
      <h3 style={{ padding: "10px", color: "#fff", fontSize: "14px" }}>
        ৳ {card.price}
      </h3>
    </div>
  );
};

export default PackageCard;
