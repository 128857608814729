import React from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import TextItem from "../utils/TextItem";

const Problems = ({ data }) => {
  return (
    <div className="effectiveness">
      <div className="container">
        <div className="problems__container">
          <h1 style={{ marginBottom: "10px" }}>অসুবিধা</h1>
          {data.Cons.length > 0 &&
            data.Cons.map((text) => (
              <TextItem
                key={text.id}
                icon={<FiArrowRightCircle className="icon" />}
                text={text.text}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Problems;
