import React, { useEffect, useState, useContext } from "react";
import { Cartcontext } from "../../context/Context";
import "./CartTop.css";
import { BsFillCartCheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import { useLocation } from "react-router-dom";
import myAxios from "../AxiosHeader";

import { getFormControlLabelUtilityClasses } from "@mui/material";

function CartTop() {
  const location = useLocation();
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [shake, setShake] = useState(false);

  const Globalstate = useContext(Cartcontext);
  const state = Globalstate.state;

  const total = state.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);

  const Pids = state.map((data) => data.id);

  useEffect(() => {
    if (total > 0) {
      AddToCartEvent({
        EventType: "AddToCartEvent",
        amount: total,
        content_ids: Pids,
      });
    }
  }, [total]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShake(true);
      setTimeout(() => setShake(false), 2000);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let is_running = false;

    if (is_running == true) {
      return;
    }

    is_running = true;
    ReactPixel.track("AddToCart");

    is_running = false;
  }, []);

  function AddToCartEvent(data) {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const source_url = `${window.location.origin}${location.pathname}`;
    const fbclid = new URLSearchParams(location.search).get("fbclid");
    const ecomId = localStorage.getItem("ecomId");
    const fbc = getCookie("_fbc");
    const fbp = getCookie("_fbp");

    const FromData = {
      eventType: data.EventType,
      fbp: fbp ? fbp : null,
      fbc: fbc ? fbc : null,
      fbclid: fbclid ? fbclid : null,
      content_ids: data.content_ids ? data.content_ids : "null",
      amount: data.amount ? data.amount : null,
      customerName: data.customerName ? data.customerName : null,
      mobileNumber: data.mobileNumber ? data.mobileNumber : null,
      source_url,
      ecomId,
    };

    myAxios
      .post("e-ConversionsAPI", FromData)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }
  return (
    <>
      {state.length ? (
        <div
          className={scrollPosition >= 87 ? "CartTopa" : "CartTop"}
          onClick={() => {
            navigate("/checkout");
          }}
        >
          <div style={{ position: "relative" }}>
            <BsFillCartCheckFill />
            <span className="cart-item-span">{state.length}</span>
          </div>
          <div>৳ {total}</div>
          <button
            onClick={() => {
              navigate("/checkout");
            }}
            className={shake ? `shake` : "shake-"}
          >
            অর্ডার করুন
          </button>
        </div>
      ) : null}
    </>
  );
}

export default CartTop;
