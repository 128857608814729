import { useLocation } from "react-router-dom";
function TrafficSource() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fbclid = searchParams.get("fbclid");
  const gclid = searchParams.get("gclid");
  
  if (fbclid) {
    if(!localStorage.getItem("TrafficSource")){
      localStorage.setItem("TrafficSource", "Facebook");
    }
  } else if (gclid) {
    if(!localStorage.getItem("TrafficSource")){
      localStorage.setItem("TrafficSource", "Google");
    }
    
  } else {
    if(!localStorage.getItem("TrafficSource")){
      localStorage.setItem("TrafficSource", "Direct");
    } 
  }
}

export default TrafficSource;
