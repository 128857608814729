import React, { useEffect, useState } from "react";
import Category from "../Category/Category";
import HotDeal from "../Offer/Offer";
import HotCategory from "../HotCategory/HotCategory";
import NewArrival from "../NewArrival/NewArrival";
import TrendingNow from "../TrendingNow/TrendingNow";
import { Helmet } from "react-helmet";
import axios from "axios";
import { URL } from "../AxiosUrlGlobal";
import Banner from "../Banner/Banner";
import SearchDesktop from "../SearchDesktop/SearchDesktop";

function Home() {
  const [store, setStore] = useState();
  const [HotProducts, setHotProducts] = useState();
  const [Trending, setTrendingNow] = useState();

  const [HCategory, setHCategory] = useState();
  const [NewArr, setNewArr] = useState();
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const heightData = screenSize.height <= 500 ? "100vh" : "100%";

  const updateScreenSize = () => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  useEffect(() => {
    axios
      .get(`${URL}/e-store`)
      .then((res) => setStore(res.data))
      .catch((err) => console.log(err.response.data));
  }, []);

  useEffect(() => {
    axios
      .get(`${URL}/e-hot-products`)
      .then((res) => setHotProducts(res.data))
      .catch((err) => console.log(err.response.data));
  }, []);

  useEffect(() => {
    axios
      .get(`${URL}/e-products`)
      .then((res) => setNewArr(res.data))
      .catch((err) => console.log(err.response.data));
  }, []);

  useEffect(() => {
    axios
      .get(`${URL}/e-trending-products`)
      .then((res) => setTrendingNow(res.data))
      .catch((err) => console.log(err.response.data));
  }, []);

  useEffect(() => {
    axios
      .get(`${URL}/e-hot-category`)
      .then((res) => setHCategory(res.data))
      .catch((err) => console.log(err.response.data));
  }, []);

  return (
    <div style={{ height: heightData }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{store?.domain ? store.domain.toUpperCase() : ""} || HOME</title>
      </Helmet>
      <SearchDesktop />
      <Banner />
      <Category />
      {HotProducts && <HotDeal data={HotProducts} />}
      {Trending && <TrendingNow data={Trending} />}
      {NewArr && <NewArrival data={NewArr} />}
      {HCategory &&
        HCategory.map((data) => {
          return <HotCategory data={data} />;
        })}
    </div>
  );
}

export default Home;
