import React, { useEffect, useState, useContext } from "react";
import { Cartcontext } from "../../context/Context";
import { useParams, useNavigate } from "react-router-dom";
import loading from "../../image/l.gif";
import "./ProductsNew.css";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { ProductSlider } from "../Slider/Slider";
import TagManager from "react-gtm-module";
import { BsMessenger } from "react-icons/bs";
import RelatedProduct from "../RelatedProduct/RelatedProduct";
import axios from "axios";
import myAxios from "../AxiosHeader";
import { URL } from "../AxiosUrlGlobal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import Modal from "../Modal/Modal";
import HashImage from "./HashImage";

function Products() {
  const [bopen, setOpen] = useState(true);
  let { id, fid } = useParams();
  const navigate = useNavigate();
  const [ShipFrom, setShipFrom] = useState();
  const [DeliveryTime, setDeliveryTime] = useState();
  const [product, setProduct] = useState([]);
  const [SortProduct, setSortProduct] = useState([]);
  const [productLoad, setProductLoad] = useState(true);
  const [price, setPrice] = useState("");
  const [Compare, setCompare] = useState();
  const [image, setimage] = useState("");
  const [code, setCode] = useState("");
  const [colorn, setColorn] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [Available, setAvailable] = useState();
  const [checkoutData, setCheckoutData] = useState();
  const [sizeData, setSizeData] = useState();
  const [Selectsize, setSelectsize] = useState(0);
  const [modal, setModal] = useState(false);
  const [OrderDataMobile, setOrderDataMobile] = useState();
  const [sizeVa, setSizeVa] = useState("");
  const [redBorder, setRedBorder] = useState(false);
  const Globalstate = useContext(Cartcontext);
  const state = Globalstate.state;
  const dispatch = Globalstate.dispatch;
  const [Number, setNumber] = useState();
  const [WhNumber, setWhNumber] = useState();
  const [DeliveryDhaka, setDeliveryDhaka] = useState();
  const [DeliveryOut, setDeliveryOut] = useState();
  const [MessangerUrl, setMessangerUrl] = useState();

  useEffect(() => {
    myAxios
      .get(`${URL}/e-store`)
      .then((res) => {
        setNumber(res.data.number);
        setWhNumber(res.data.WhatsApp);
        setDeliveryDhaka(res.data.Dhaka);
        setDeliveryOut(res.data.Outside);
        setMessangerUrl(res.data.MessangerUrl);
      })
      .catch((err) => console.log(err.response.data));
  }, []);

  let checkoutDatafilter;
  if (sizeVa) {
    checkoutDatafilter = checkoutData?.sizes.filter((data) => {
      return data.sizeName === sizeVa;
    });
  }

  let rdata;
  let stock_color_size;
  checkoutDatafilter?.map((data) => {
    rdata = data.gid;
    stock_color_size = data.quantity;
  });

  const stock_color = checkoutData?.quantity;
  const stock = product?.quantity;

  useEffect(() => {
    if (Selectsize) {
      setPriceSize();
    }
  }, [Selectsize]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const setPriceSize = () => {
    const getPrice = sizeData[Selectsize];
    setPrice(getPrice.price);
    setSizeVa(getPrice.sizeName);
  };

  const DataLayerAddToCart = (data) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "addToCart",
        ecommerce: {
          currencyCode: "BDT",
          add: {
            products: [
              {
                id: data.id,
                name: data.colorName,
                price: data.price,
                quantity: data.quantity,
              },
            ],
          },
        },
      },
    });
  };

  const pInc = () => {
    if (quantity >= Available) {
      toast.error("Quantity Limit");
      setQuantity(1);
    } else {
      setQuantity(parseInt(quantity + 1));
    }
  };
  const pDic = () => {
    if (quantity <= 1) {
      return;
    }
    setQuantity(parseInt(quantity - 1));
  };

  const checkout = () => {
    if (stock_color_size <= 0 || stock_color <= 0 || stock <= 0) {
      return toast.error("Out Of Stock");
    }
    if (product.variation === "color") {
      if (!checkoutData) {
        setQuantity(1);
        return setRedBorder(true);
      }
    }

    if (product.variation === "color") {
      toast.success("কার্ট এ অ্যাড করা হয়েছে");
      let modifycolor = Object.assign(checkoutData, {
        quantity: quantity,
        VariationType: product.variation,
        title: product.productName,
        id: product.id,
        pCode: product.pCode,
        TimeNow: Date.now(),
        Available: Available,
      });
      setCheckoutData(modifycolor);
      DataLayerAddToCart(modifycolor);
      dispatch({ type: "ADD", payload: checkoutData });
    }
  };

  const orderNowButton = () => {
    if (stock_color_size <= 0 || stock_color <= 0 || stock <= 0) {
      return toast.error("Out Of Stock");
    }
    if (product.variation === "color") {
      if (!checkoutData) {
        setQuantity(1);
        return setRedBorder(true);
      }
    }

    if (product.variation === "color") {
      let modifycolor = Object.assign(checkoutData, {
        quantity: quantity,
        VariationType: product.variation,
        title: product.productName,
        id: product.id,
        pCode: product.pCode,
        TimeNow: Date.now(),
        Available: Available,
      });

      setCheckoutData(modifycolor);
      DataLayerAddToCart(modifycolor);
      dispatch({ type: "ADD", payload: checkoutData });
    }

    state.map((data) => {
      if (
        data.colorName === product.color ||
        data.colorName === checkoutData.colorName
      ) {
        toast.success("Already added to cart");
      }
    });

    navigate("/checkout");
  };

  const AddtoCartMobile = (data) => {
    if (data.quantity <= 0) {
      toast.error("Out Of Stock");
      return;
    }
    const orderData = {
      VariationType: product.variation,
      colorName: data.colorName,
      gid: data.gid,
      id: product.id,
      image: data.image,
      price: data.price,
      quantity: 1,
      title: product.productName,
      pCode: product.pCode,
      ShipFrom: data.ShipFrom,
      Available: data.quantity,
      TimeNow: Date.now(),
    };
    DataLayerAddToCart(orderData);
    dispatch({ type: "ADD", payload: orderData });
    setOrderDataMobile(orderData);
    toast.success(`${data.colorName} কার্ট এ অ্যাড করা হয়েছে`);
    setModal(true);
  };

  const BuyNowMobile = (data) => {
    console.log(data);
    if (data.quantity <= 0) {
      toast.error("Out Of Stock");
      return;
    }
    const orderData = {
      VariationType: product.variation,
      colorName: data.colorName,
      gid: data.gid,
      id: product.id,
      image: data.image,
      price: data.price,
      quantity: 1,
      title: product.productName,
      pCode: product.pCode,
      ShipFrom: data.ShipFrom,
      Available: data.quantity,
      TimeNow: Date.now(),
    };
    DataLayerAddToCart(orderData);
    dispatch({ type: "ADD", payload: orderData });
    setOrderDataMobile(orderData);
    setModal(true);
  };

  useEffect(() => {
    axios
      .get(`${URL}/e-product/${id}`)
      .then((res) => {
        setProduct(res.data);
        setProductLoad(false);
        setOpen(false);
        if (res.data === "No product found..") {
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    if (product.VariationData) {
      ProductList(fid);
    }
  }, [product]);

  function ProductList(searchTerm) {
    const sortedItems = [...product.VariationData];

    sortedItems.sort((a, b) => {
      const includesWordA = a.colorName.includes(searchTerm);
      const includesWordB = b.colorName.includes(searchTerm);

      if (includesWordA && !includesWordB) {
        return -1;
      } else if (!includesWordA && includesWordB) {
        return 1;
      } else {
        return 0;
      }
    });
    setSortProduct(sortedItems);
  }
  return (
    <>
      {modal && (
        <Modal
          setModal={setModal}
          data={OrderDataMobile}
          Available={Available}
        />
      )}
      <Helmet>
        <meta charSet="n-utf-8" />
        <title>{product.productName}</title>
      </Helmet>

      {productLoad && (
        <div
          style={{
            width: "100vw",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
            transition: "1s",
          }}
        >
          <h1>Loading...</h1>
        </div>
      )}

      {product && (
        <motion.div
          className="n-main-product"
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ToastContainer />
          <div className="n-image-and-buynow">
            <div className="n-product-desktop">
              <div className="n-product-photo">
                {product.images &&
                product.images.length > 0 &&
                product.images[0].url ? (
                  // <ProductSlider data={product.images} />
                  <img
                    src={
                      image
                        ? image
                        : product.imageUrl
                        ? product.imageUrl
                        : product.images[0].url
                    }
                    alt="productimage"
                  />
                ) : (
                  <img
                    src={
                      image ? image : product.imageUrl ? product.imageUrl : ""
                    }
                    alt="productimage"
                  />
                )}
              </div>
            </div>

            {product.offerMessage && (
              <div className="show-offer">
                <h4>OFFER</h4>
                <p>{product.offerMessage}</p>
              </div>
            )}

            {product.VariationData &&
              SortProduct.map((data, index) => {
                return (
                  <div className="n-mobile-product" key={index}>
                    <div className="n-product-photo">
                      <div className="n-code-and-stock-on-image">
                        <div className="n-Product-code-image">
                          <p>{data.colorName}</p>
                        </div>
                        <div className="n-Product-price-on-image">
                          <p className="n-product-price-line-through-on-image">
                            ৳ {data.PriceCompare}
                          </p>
                          <p className="n-product-price-on-image">
                            ৳ {data.price}
                          </p>
                        </div>
                      </div>
                      {/* <HashImage src={data.image} /> */}
                      <img src={data.image} alt="n-productimage" />
                    </div>
                    <div className="ship-time">
                      <p>Delivery Time: {data.DeliveryTime} days</p>
                      <p>Ship From: {data.ShipFrom}</p>
                    </div>

                    <div className="n-mobile-order-card">
                      <div
                        className="n-mobile-order"
                        onClick={() => BuyNowMobile(data)}
                      >
                        অর্ডার করুন
                      </div>
                      <div
                        className="n-mobile-cart"
                        onClick={() => AddtoCartMobile(data)}
                      >
                        কার্ট-এ যোগ করুন
                      </div>
                    </div>
                  </div>
                );
              })}

            <div className="n-product-buy-now">
              <h2>{product.productName?.substring(0, 45) + "..."}</h2>
              <div className="n-detail-price">
                <p className="n-product-price-line-through">
                  ৳ {Compare ? Compare : product.compare}
                </p>
                <p className="n-product-price-">
                  ৳{price ? price : product.proceSell}
                </p>
              </div>

              <p className="n-product-code">
                প্রোডাক্ট কোড: {code ? code : product.pCode}
              </p>

              {DeliveryTime && <p>Delivery Time: {DeliveryTime} days</p>}
              {ShipFrom && <p>Ship From: {ShipFrom}</p>}

              {product.variation === "color" && (
                <div className="n-color-variation">
                  <p>Color: {colorn ? colorn : null}</p>
                  {redBorder && (
                    <div
                      style={{
                        color: "red",
                        border: "2px solid red",
                        padding: "10px",
                      }}
                    >
                      নিচে যেকোনো একটা ফটোতে ক্লিক করে কালার সিলেক্ট করুন
                    </div>
                  )}
                  <div className="n-color-image">
                    {product &&
                      SortProduct?.map((data, index) => {
                        return (
                          <img
                            key={index}
                            src={data.image}
                            alt={data.colorName}
                            onClick={() => {
                              setimage(data.image);
                              setPrice(data.price);
                              setCode(data.colorName);
                              setColorn(data.colorName);
                              setShipFrom(data.ShipFrom);
                              setDeliveryTime(data.DeliveryTime);
                              setCompare(data.PriceCompare);
                              setCheckoutData(data);
                              setQuantity(1);
                              setAvailable(data.quantity);
                              setRedBorder(false);
                              toast.success(
                                `${data.colorName} কালার সিলেক্ট হয়েছে`
                              );
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              )}

              <div className="n-Quantity-plus-">
                <p>পরিমান</p>
                <button onClick={pDic}>-</button>
                <input
                  type="number"
                  className="n-Quantity-Input"
                  name="n-Quantity"
                  value={quantity}
                  onChange={(e) => {
                    if (typeof e.target.value != "number") {
                      return;
                    } else {
                      setQuantity(e.target.value);
                    }
                  }}
                />
                <button onClick={pInc}>+</button>
              </div>
              <button
                className="n-order-button-product"
                onClick={orderNowButton}
              >
                অর্ডার করুন
              </button>
              <button className="n-cart-button-product" onClick={checkout}>
                কার্ট-এ যোগ করুন
              </button>
              <h2>ফোনে অর্ডারের জন্য ডায়াল করুন</h2>

              <div className="n-call-product">
                <FaPhoneSquareAlt className="n-product-d-icon" />
                <a
                  style={{ color: "#fff", textDecoration: "none" }}
                  href={`tel:+88${Number}`}
                >
                  {Number}
                </a>
              </div>
              <div className="call-product">
                <WhatsAppIcon className="product-d-icon" />
                <a
                  style={{ color: "#fff", textDecoration: "none" }}
                  href={`https://wa.me/+88${WhNumber}`}
                  target="_blabk"
                >
                  WhatsApp
                </a>
              </div>
              <div className="pro-facebtn ">
                <BsMessenger className="product-d-icon" />
                <a
                  style={{ color: "#fff", textDecoration: "none" }}
                  href={MessangerUrl}
                  target="_blabk"
                >
                  Messanger
                </a>
              </div>
              <div className="n-product-delivery">
                <p>ঢাকার ভিতরে ডেলিভারি</p>
                <p>৳ {DeliveryDhaka}</p>
              </div>
              <div className="n-product-delivery">
                <p>ঢাকার বাইরে ডেলিভারি</p>
                <p>৳ {DeliveryOut}</p>
              </div>
            </div>
          </div>
          <div className="n-product-description">
            <div className="n-des-faq">
              <div className="n-des">
                <h2>Description</h2>
                <div
                  className="n-Product-Desc-in"
                  dangerouslySetInnerHTML={{ __html: product.productDec }}
                />
              </div>
              {/* <div className="n-faq">
                <h2>F.A.Q</h2>
                <p>
                  আপনার কোন প্রশ্ন জানার থাকলে নিচের " আমার প্রশ্ন আছে " বাটন
                  টিতে ক্লিক করে আপনার মোবাইল নাম্বার আর আপনার প্রশ্নটি লিখে
                  সাবমিট করুন আমরা আপনাকে কল করে জানাবো
                </p>
                <button className="n-faq-button">আমার প্রশ্ন আছে</button>
              </div> */}
            </div>
          </div>
          {product && <RelatedProduct data={product} />}
        </motion.div>
      )}
    </>
  );
}

export default Products;
