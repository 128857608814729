import React from "react";
import "./NewMenue.css";
class Navbar extends React.Component {
  render() {
    return (
      <nav className="navbar">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a href="#home">Home</a>
          </li>
          <li className="nav-item">
            <a href="#link">Link</a>
          </li>
          <li className="nav-item dropdown">
            <a href="#dropdown" className="dropdown-toggle">
              Dropdown
            </a>
            <ul className="dropdown-menu">
              <li>
                <a href="#action/3.1">Action</a>
              </li>
              <li>
                <a href="#action/3.2">Another action</a>
              </li>
              <li>
                <a href="#action/3.3">Something</a>
              </li>
              <li className="divider"></li>
              <li>
                <a href="#action/3.4">Separated link</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;
