import axios from "axios";
const BaseUrl = "https://bapi.ecomtechbd.com";

export default function BkashCreatePaymentAPICall(total, Invoice, OrderId) {
  axios
    .post(`${BaseUrl}/api/bkash/create`, {
      amount: total,
      callbackURL: `${window.location.protocol}//${window.location.host}/callback`,
      Invoice: Invoice,
      OrderId: OrderId,
    })
    .then((response) => {
      console.log("Data was successfully sent.", response);
      axios
        .post(
          `${BaseUrl}/save-payment/${response.data.merchantInvoiceNumber}`,
          { paymentID: response.data.paymentID }
        )
        .then((res) => console.log(res.data))
        .catch((e) => console.log(e.response.data));

      if (response?.data?.bkashURL) {
        window.location.href = response?.data?.bkashURL;
      } else {
        window.location.href = "/";
      }
    })
    .catch((error) => {
      console.log("An error occurred:", error);
    });
}
