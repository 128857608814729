import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Description from "./Description";
import Features from "./Features";
import Effectiveness from "./Effectiveness";
import EffectivenessCards from "./EffectivenessCards";
import { EffectivenessContentList } from "./EffectivenessContentList";
import Problems from "./Problems";
import Decisions from "./Decisions";
import { Questions } from "./Questions";
import axios from "axios";
import { URL } from "../../AxiosUrlGlobal";
import ReactLoading from "react-loading";

import "../landing-1.css";

const Home = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    axios
      .get(`${URL}/get-landing-front/${id}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [id]);

  return (
    <>
      {loading && (
        <div
          style={{
            width: "100vw",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
            transition: "1s",
          }}
        >
          <ReactLoading
            type="spin"
            color="rgb(226,115,29)"
            height={60}
            width={60}
          />
        </div>
      )}

      {error && (
        <div
          style={{
            width: "100vw",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
            transition: "1s",
          }}
        >
          <div>404 Page not Found</div>
        </div>
      )}

      {!loading && !error && (
        <div>
          <Description data={data} />
          <Features data={data} />
          <Effectiveness data={data} />
          <EffectivenessCards data={data} />
          <EffectivenessContentList data={data} />
          <Problems data={data} />
          <Questions data={data} />
          <Decisions data={data} />
        </div>
      )}
    </>
  );
};

export default Home;
