import { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import "./Header.css";

import FaceBookPixel from "../Helper/FacebookPixel";
import ConversionsAPI from "../Helper/ConversionsAPI";
import GoogleAnalytic from "../Helper/GoogleAnalytic";
import GoogleTag from "../Helper/GoogleTag";
import Suspend from "../Helper/Suspend";

import CartTop from "../CartTop/CartTop";
import Notification from "../Notification/Notification";

import myAxios from "../AxiosHeader";
import { URL } from "../AxiosUrlGlobal";

import { useNavigate } from "react-router-dom";

import TrafficSource from "../../action/TrafficSource";

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();

  const [loadCategory, setLoadCategory] = useState(true);
  const [suspend, setSuspend] = useState(false);
  const [product, setProduct] = useState([]);
  const [zeroCategory, setZeroCategory] = useState(false);
  const [store, setStore] = useState();
  const [FAccessToken, setFAccessToken] = useState();
  const [logoweb, setLogoweb] = useState();
  const [alt, setAlt] = useState();
  const navRef = useRef();

  TrafficSource();
  ConversionsAPI({ EventType: "PageViewEvent" });

  useEffect(() => {
    if (suspend) {
      navigate("/suspend");
    }
  }, [location.pathname, suspend]);

  useEffect(() => {
    ReactPixel.pageView();
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });

    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: location.pathname,
      },
    });
  }, [location.pathname]);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  useEffect(() => {
    if (product.length === 0 && !loadCategory) {
      setZeroCategory(true);
    }
  }, [loadCategory]);

  useEffect(() => {
    myAxios
      .get(`${URL}/e-store`)
      .then((res) => {
        if (res.data.Suspend == 1) {
          setSuspend(true);
        }
        setStore(res.data);
        setAlt(res.data.name);

        if (res.data.pixel) {
          FaceBookPixel(res.data.pixel);
        }

        if (res.data.GoogleAdsTag) {
          GoogleTag(res.data.GoogleAdsTag);
        }

        if (res.data.GoogleAnalytic) {
          GoogleAnalytic(res.data.GoogleAnalytic);
        }

        if (res.data.FAccessToken) {
          setFAccessToken(res.data.FAccessToken);
        }
      })
      .catch((err) => console.log(err.response.data));
  }, []);

  useEffect(() => {
    myAxios
      .get(`${URL}/e-category`)
      .then((res) => {
        setProduct(res.data);
        setLoadCategory(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("ecomId")) {
      localStorage.setItem("ecomId", generateRandomId());
    }
  }, []);

  function generateRandomId() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";
    for (let i = 0; i < 50; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }
    return randomId;
  }

  return (
    <>
      <CartTop />
      {/* <Notification /> */}
      <header>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <div className="logoMain">
            <img
              className="h-logo"
              src={store ? store.logo : ""}
              alt={alt ? alt : ""}
            />
          </div>

          {/* <h2 className="h-logo">{store ? store.store : ""}</h2> */}
        </Link>
        <nav ref={navRef}>
          {zeroCategory && <div>No menue found..</div>}
          {product.map((data, index) => {
            return (
              <div key={index}>
                <a
                  onClick={() => {
                    showNavbar();
                    navigate(`/view-category/${data.id}`);
                  }}
                >
                  {data.name}
                </a>
              </div>
            );
          })}
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>

        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    </>
  );
}

export default Navbar;
